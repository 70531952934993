import React from 'react';

import SingleSlashIcon from '@heroicons/react/24/outline/SignalSlashIcon';
import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import { Button, Icon, twMerge } from 'atp-react-ui';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { Outlet } from 'react-router-dom';

import { queryClient } from '_utils';

export function ErrorBoundary({ className }) {
  const { reset } = useQueryErrorResetBoundary();

  const onResetError = React.useCallback(() => {
    /* 
      Need to clear react-query client. It's needed because
      in case of access token getting renewed after expiration (refresh token)
      the client keeps the stale error data which causes app crash on some instances.
    */
    queryClient.removeQueries();
    reset();
  }, [reset]);

  return (
    <ReactErrorBoundary fallbackRender={(props) => <OnError {...props} className={className} />} onReset={onResetError}>
      <Outlet />
    </ReactErrorBoundary>
  );
}

export function OnError({ resetErrorBoundary, className }) {
  return (
    <section className={twMerge('pt-20 flex flex-col items-center justify-center', className)}>
      <Icon icon={SingleSlashIcon} className="size-16 text-accent" />
      <div className="mb-8 mt-2 w-96 text-center text-accent-light">
        Oops, something went wrong.
        <br />
        Try fixing your internet connection and try again.
      </div>
      <Button onClick={() => resetErrorBoundary()}>Try again</Button>
    </section>
  );
}
