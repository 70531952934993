import { useEffect } from 'react';

import { useLocation, useParams } from 'react-router-dom';

import PagePreLoader from '_components/preloader';
import ROUTES from '_navigation/routes';
import { openWindow } from '_utils';

export default function RedirectToMeetNow() {
  const { id } = useParams();
  const { state } = useLocation();
  const { from } = state || { from: '' };
  const flag = from === ROUTES.BRIDGE_PLAN_PRE_BUY_RES.link ? '&key=YnJpZGdlLXBsYW4vcHJlLWJ1eS9yZXM' : '';

  useEffect(() => {
    localStorage.clear();
    const url = `${process.env.REACT_APP_MEET_PORTAL_LINK}/now?token=${id}`;
    console.log('url', url);
    openWindow(url);
  }, [flag, id]);

  return (
    <div className="bg-primary-xx-light">
      <PagePreLoader fullScreen />
    </div>
  );
}
