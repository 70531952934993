import { useRef, useState } from 'react';

import ArrowRightIcon from '@heroicons/react/24/outline/ArrowRightIcon';
import CheckCircleIcon from '@heroicons/react/24/outline/CheckCircleIcon';
import { Button, Card, CardBody, Icon, Text } from 'atp-react-ui';
import Carousel from 'react-elastic-carousel';

import ROUTES from '_navigation/routes';
import AdvisorJermaine from '_static/assets/advisorJermaine.png';
import AdvisorLinda from '_static/assets/advisorLinda.png';
import AdvisorMari from '_static/assets/advisorMari.png';
import { ReactComponent as FinishFaster } from '_static/assets/finishFasterNew.svg';
import { ReactComponent as Only19 } from '_static/assets/only19_1.svg';
import { getTimeValidity, useUrlParams } from '_utils';

function PageCard({
  navigate,
  currentObj,
  buttonIsLoading,
  handleSubmitLeadQualification,
  handleGoogleEvent,
  leadObj,
  updateCategory,
  isPending,
}) {
  const carouselRef = useRef(null);
  const buttonRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const { dev_setPortal } = useUrlParams();
  const { isValidTimeToMeet } = getTimeValidity(dev_setPortal);
  const canMeetNow = !(currentObj.timeSensitive && !isValidTimeToMeet);

  let resetTimeout;

  console.log('currentObj', currentObj, canMeetNow);
  function WhyShouldBridge() {
    const quote = `"A goal without a plan is just a wish."`;
    const text = `Yes! This research would take significant time and effort on your own. Let our experts do the work to tell you exactly which courses to take, when to take them, and how much they’ll cost.`;
    return (
      <>
        <Text as="h1" className="mb-4 mt-8 text-center font-serif font-semibold">
          Should you get a Bridge Plan?
        </Text>
        <Text as="caption-medium" className="py-4 text-center text-xl text-accent-medium">
          {text}
        </Text>
        <Text as="caption" className="text-center text-base font-medium italic text-secondary">
          {quote}
        </Text>
      </>
    );
  }

  function BpPreBuyReservation() {
    // const text = `${leadObj?.First_Name__c}, would you like to work with an Advisor to build your Bridge Plan right now?`;
    return (
      <>
        <Text as="h1" className="mb-4 mt-8 text-center font-serif font-semibold">
          Our Advisors customize every Bridge Plan after assessing individual goals.{' '}
        </Text>
        <Text as="caption-medium" className="py-4 text-center text-xl text-accent-medium">
          Do you have time to start a call now? Our Advisors are ready to talk when you are.
        </Text>
      </>
    );
  }

  function ImmediatelyReservation() {
    return (
      <>
        <Text as="h1" className="mb-4 mt-8 text-center font-serif font-semibold">
          {leadObj?.First_Name__c}, let’s explore RN Bridge options tailored just for you...
        </Text>
        <Text as="caption-medium" className="py-4 text-center text-xl text-accent-medium">
          Take the next essential step toward discovering the right path for your nursing career goals. Our Advisors are
          ready to talk when you are.
        </Text>
      </>
    );
  }

  function PlanFinishFaster() {
    return (
      <>
        <div className="mt-8 flex items-center justify-center">
          <FinishFaster className="h-24" />
        </div>
        <Text as="h1" className="mb-4 mt-6 text-center font-serif font-semibold">
          Take the <span className={`bold italic`}>next step</span>
        </Text>
        <Text as="caption-medium" className="py-4 text-center text-xl text-accent-medium">
          We’re ready to personalize a Bridge Plan just for you. This detailed, 3-page document puts your RN future into
          focus.
        </Text>
      </>
    );
  }

  function PlanWithExperts() {
    return (
      <>
        <div className="mx-auto my-0 mt-8 w-[14.375rem]">
          <Carousel
            itemsToShow={1}
            showArrows={false}
            className={`
            [&>.rec-pagination>.rec-dot]:h-2 
            [&>.rec-pagination>.rec-dot]:w-2 
            [&>.rec-pagination>.rec-dot]:border 
            [&>.rec-pagination>.rec-dot]:border-solid 
            [&>.rec-pagination>.rec-dot]:border-primary 
            [&>.rec-pagination>.rec-dot]:bg-transparent 
            [&>.rec-pagination>.rec-dot]:shadow-none`}
            enableAutoPlay
            autoPlaySpeed={2000}
            ref={carouselRef}
            onNextEnd={({ index }) => {
              clearTimeout(resetTimeout);
              if (index + 1 === 3) {
                resetTimeout = setTimeout(() => {
                  carouselRef?.current?.goTo(0);
                }, 2000);
              }
            }}
          >
            <div>
              <img src={AdvisorMari} className="w-[12rem]" alt="Advisor Mari" />
            </div>
            <div>
              <img src={AdvisorJermaine} className="w-[12rem]" alt="Advisor Jermaine" />
            </div>
            <div>
              <img src={AdvisorLinda} className="w-[12rem]" alt="Advisor Linda" />
            </div>
          </Carousel>
        </div>
        <Text as="h1" className="mb-4 mt-8 text-center font-serif font-semibold">
          Plan <span className={`bold italic`}>with experts</span>
        </Text>
        <Text as="caption-medium" className="py-4 text-center text-xl text-accent-medium">
          You’ll be matched with a skilled Advisor who has successfully built thousands of custom Bridge Plans.
        </Text>
      </>
    );
  }

  function YouAndYourAdvisor() {
    const renderHtml = () => {
      const html = [
        {
          id: 1,
          icon: <Icon icon={CheckCircleIcon} className="h-8 w-8 text-primary-medium" />,
          body: 'Finds the right accredited nursing college for you.',
        },
        {
          id: 2,
          icon: <Icon icon={CheckCircleIcon} className="h-8 w-8 text-primary-medium" />,
          body: 'Confirms which courses you can fast track.',
        },
        {
          id: 3,
          icon: <Icon icon={CheckCircleIcon} className="h-8 w-8 text-primary-medium" />,
          body: 'Verifies transferable credits to your accredited college.',
        },
      ];

      return (
        <div className="w-full px-10">
          <section className={`mt-8 flex flex-col`}>
            {html.map((obj) => {
              return (
                <div className="my-2 flex items-center" key={obj.id}>
                  <div>
                    <Icon icon={CheckCircleIcon} className="h-8 w-8 text-primary-medium" />
                  </div>
                  <div className="pl-4 text-base text-accent-medium">{obj.body}</div>
                </div>
              );
            })}
          </section>
        </div>
      );
    };

    return (
      <>
        <Text as="h1" className="mb-4 mt-8 text-center font-serif font-semibold">
          Build a Bridge Plan with your Advisor that:
        </Text>
        {renderHtml()}
      </>
    );
  }

  function GetYourBridgePlan() {
    return (
      <>
        <Text as="h1" className="mb-4 mt-8 text-center text-gray-700 font-serif font-semibold">
          Kickstart your RN goals with a custom College Bridge Plan.
        </Text>
        <div className="flex items-center justify-center">
          <Only19 className="my-5" />
        </div>
        <div className="mt-4 text-center italic text-gray-500 text-sm tracking-wide">
          Receive a plan tailored to your career goals, budget, and schedule.
        </div>
      </>
    );
  }

  const handleRenderHTML = (key) => {
    switch (key) {
      case 'bpPreBuyReservation':
        return <BpPreBuyReservation />;
      case 'immediateReservation':
        return <ImmediatelyReservation />;
      case 'whyShouldBridge':
        return <WhyShouldBridge />;
      case 'planFinishFaster':
        return <PlanFinishFaster />;
      case 'planWithExperts':
        return <PlanWithExperts />;
      case 'youAndYourAdvisor':
        return <YouAndYourAdvisor />;
      case 'prebuyyourbridge':
        return <GetYourBridgePlan />;
      default:
        return null;
    }
  };

  const handleClick = (e, meetnow = false) => {
    e.preventDefault();
    if (currentObj?.customKey === 'immediateReservation') {
      setIsLoading(true);
      updateCategory(currentObj?.category);
      handleSubmitLeadQualification(false, () => {
        setIsLoading(false);
        navigate(currentObj.nextPage);
      });
    } else if (currentObj?.customKey === 'bpPreBuyReservation' && meetnow) {
      updateCategory(currentObj?.category);
      handleGoogleEvent('no_pre_buy_calendly', true);
      handleSubmitLeadQualification('meet_now');
    } else if (currentObj?.customKey === 'bpPreBuyReservation' && !meetnow) {
      setIsLoading(true);
      updateCategory(currentObj?.secondaryCategory);
      handleSubmitLeadQualification(false, () => {
        setIsLoading(false);
        navigate(ROUTES.BRIDGE_PLAN_PRE_BUY_RES_SCHEDULED.link);
      });
    } else if (
      (currentObj?.customKey === 'whyShouldBridge' || currentObj?.customKey === 'prebuyyourbridge') &&
      meetnow
    ) {
      setIsLoading(true);
      updateCategory(`${currentObj?.category}${currentObj?.customKey === 'prebuyyourbridge' ? 'Yes' : ''}`);
      if (currentObj?.customKey === 'prebuyyourbridge') {
        handleGoogleEvent('pre_buy', true);
      }
      handleSubmitLeadQualification(false, () => {
        setIsLoading(false);
        navigate(currentObj?.nextPage);
      });
    } else {
      navigate(currentObj?.nextPage);
    }
  };

  const handleContinueWOSavingsGA = () => {
    if (isLoading) return;
    setIsLoading(true);
    updateCategory(`${currentObj?.category}No`);
    handleSubmitLeadQualification(false, () => {
      setIsLoading(false);
      handleGoogleEvent('no_pre_buy', true);

      /** *** // !important 06/10/22 */
      navigate(ROUTES.BRIDGE_PLAN_WO_PRE_BUY_READY.link);
      /** *** // !important 06/10/22 */
    });
  };

  const handleContinueLink = () => {
    switch (currentObj?.customKey) {
      case 'prebuyyourbridge':
        return (
          <div className="w-full">
            <Button
              theme="primary"
              as="link"
              className="mt-1 underline focus:ring-0 disabled:border-primary-light disabled:bg-primary-light disabled:text-primary-medium"
              onClick={() => handleContinueWOSavingsGA()}
            >
              Continue without a plan
            </Button>
          </div>
        );
      case 'bpPreBuyReservation':
        return (
          <div className="w-full">
            <Button
              theme="primary"
              as="link"
              className="min-w-48 rounded-none text-xl font-bold disabled:border-primary-light disabled:bg-primary-light disabled:text-primary-medium"
              onClick={(e) => handleClick(e)}
            >
              Schedule a time
            </Button>
          </div>
        );
      default:
        return <div />;
    }
  };

  const continueLink = handleContinueLink();
  const renderHTML = handleRenderHTML(currentObj?.customKey);

  return (
    <Card className="shadow-card-primary-light">
      <CardBody>
        {renderHTML}
        {!currentObj?.hideButton && (
          <div className="mt-8 flex w-full flex-col items-center justify-center">
            <Button
              ref={buttonRef}
              theme="primary"
              size="lg"
              className="min-w-48 rounded-none text-xl font-bold disabled:border-primary-light disabled:bg-primary-light disabled:text-primary-medium"
              onClick={(e) => handleClick(e, true)}
              showLoader={!(!buttonIsLoading && !isLoading) || isPending}
              iconRight={currentObj?.hideButtonIcon || isPending ? null : ArrowRightIcon}
            >
              {isPending ? 'Please wait...' : currentObj.buttonText}
            </Button>

            <div className="mb-5 mt-2">{continueLink}</div>
          </div>
        )}
      </CardBody>
    </Card>
  );
}

export default PageCard;
