import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';

import { ErrorBoundary } from '_components';
import BridgeQualify from '_pages/bridge-plan';
import { MeetingConfirmationPage } from '_pages/components/MeetingConfirmation';
import RedirectResolver from '_pages/redirect-reolver';
import RedirectToMeetNow from '_pages/redirecting-to-meet';
import { LeadProvider } from '_provider';

import ROUTES from './_navigation/routes';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<ErrorBoundary />}>
      <Route path={ROUTES.CONFIRMATION.link} element={<MeetingConfirmationPage />} />
      <Route element={<LeadProvider />}>
        <Route path={ROUTES.REDIRECT_TO_MEET_NOW.link} element={<RedirectToMeetNow />} />
        {Object.values(ROUTES).map((route) => {
          if (route?.isNotBp) {
            return null;
          } else {
            return <Route key={route.link} path={route.link} element={<BridgeQualify />} />;
          }
        })}

        <Route path="*" element={<RedirectResolver />} />
      </Route>
    </Route>
  )
);

export default router;
