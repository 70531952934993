const CATEGORIES = {
  START_QUALIFY: 'Start Qualify',
  MINDSET_QUALIFY: 'Mindset Qualify',
  MINDSET_REPATH_NOT_READY: 'Mindset Repath Not Ready',

  GENERAL_EDUCATION: 'General Education',

  GENERAL_EDUCATION_REPATH_NOT_READY: 'Gen Ed Repath Not Ready', // legacy
  GEN_ED_REPATH_PASSED_ALL: 'Gen Ed Repath Passed All',

  ENTRANCE_EXAM: 'Entrance Exam (TEAS)',
  REPATH_PASSED_ENTRANCE_EXAM: 'Repath Passed Entrance Exam',

  CORE_NURSING_COURSES: 'Core Nursing Courses', // legacy
  CORE_RN_COURSES: 'Core RN Courses',
  REPATH_PASSED_CORE_RN_COURSES: 'Repath Passed RN Courses',

  LICENSURE_EXAM: 'Licensure Exam (NCLEX)', // legacy
  EXIT_EXAM: 'Exit Exam',
  REPATH_PASSED_EXIT_EXAM: 'Repath Passed Exit Exam',

  RESULT_QUALIFY: 'Results Qualify',

  BP_INFO: 'BP Info',
  BP_PRE_BUY: 'BP Pre-buy',
  BP_PRE_BUY_YES: 'BP Pre-buyYes',
  BP_PRE_BUY_NO: 'BP Pre-buyNo',
  READINESS: 'Readiness',
  READY_IMMEDIATE_RES_MEET: 'Ready-Immediate Res-meet',
  READY_IMMEDIATE_NON_BUSINESS_HOURS: 'Ready Immediate-Non-Business Hours',
  READY_IMMEDIATE_RES_SCHEDULED: 'Ready-Immediate Res-Scheduled',
  READY_IMMEDIATE_RES_CONFIRMED: 'Ready-Immediate Res-Confirmed',
  READY_SOON_RES_SCHEDULE: 'Ready-Soon Res-Schedule',
  READY_NOT_YET_RES_SCHEDULE: 'Ready-Not Yet Res-Sched',
  READY_NOT_YET_VIDEO: 'Ready-Not Yet Video',
  READY_NOT_YET_OPTIONS: 'Ready-Not Yet Options',
  READY_NOT_YET_LEARN_HOME: 'Ready-Not Yet Learn-Home',

  BRIDGE_PLAN_PURCHASED: 'Bridge Plan Purchased', // legacy
  BP_PRE_BUY_PURCHASED: 'BP Pre-Buy Purchased',

  BP_PRE_BUY_RES_MEET: 'BP Pre-Buy Res-Meet',
  BP_PRE_BUY_RES_SCHEDULE: 'BP Pre-Buy Res-Schedule',

  BP_PRE_BUY_RES_CONFIRMED: 'BP Pre-Buy Res-Confirmed',
  READY_NOT_YET_RES_CONFIRMED: 'Ready-Not Yet Res-confirmed',
  READY_SOON_RES_CONFIRMED: 'Ready-Soon Res-confirmed',
};

export default CATEGORIES;
